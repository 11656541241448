div.FlatMapCard {
  border-radius: 8px;
  background-color: #F5F6FA;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  > section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    > label {
      font-weight: bold;
    }
  }

  > div {
    background-color: white;
    padding: 1rem;
    height: 48p;
    border-radius: 48px;
    display: flex;
    gap: 1rem;
    > a {
      color: black;
      &.active {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  > a {
    flex-grow: 0;
    width: 16px;
  }
}