article.flat-card {

  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: solid 1px #ECECEC;
  margin-bottom: 1rem;

  > h4 {
    margin: 1rem 0 1rem 0;
    font-weight: bold;
  }

  > section {
    display: flex;
    justify-content: space-between;

    > label.floor {
      border-bottom: solid 2px black;
    }

    > div {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: .5rem;
      > label {
        display: flex;
        gap: .5rem;
      }
    }
  }

}