div[component='FlatFilter'] {

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 1rem 0;

  > div.aptbuttons {
    display: flex;
    gap: 4px;
    button {
      width: 40px;
      height: 40px;
      border: solid 2px black;
      border-radius: 4px;
      background-color: transparent;

      &.active {
        background-color: black;
        color: white;
      }

      &.disabled {
        color: #9C9C9C;
        border-color: #9C9C9C;
        background-color: #F7F7F7;
      }
    }
  }

}