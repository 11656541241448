body#app-storefront-floor {

  > header {

    padding: 1rem;

    > section {
      display: flex;
      justify-content: space-between;
    }

    > h1 {
      margin: 0;
      font-size: 28px;
      font-weight: bold;
    }
  }

  > main {
    padding: 0 1rem 1rem 1rem;

    > h4 {
      font-size: 21px;
      font-weight: bold;
      margin: 0;
    }
  }
}