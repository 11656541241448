body#app-storefront-index {

  > header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;

    > h1 {
      margin: 0;
      font-size: 36px;
      font-weight: bold;
    }
  }

  > main {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > article {
      border-radius: 1rem;
      border: solid 1px silver;
      > img {
        border-radius: 1rem 1rem 0 0;
      }
      > main {
        padding: 1rem;
        > h2 {
          margin: 1rem 0;
        }
      }
    }
  }
}

// mobile
@media screen and (max-width: 479px) {
}
// tablet and small screens
@media screen and (min-width: 480px) and (max-width: 959px) {
}
// desktop
@media screen and (min-width: 960px) {
}

@import "region";
@import "floor";
@import "mobile-footer";
@import "css/breadcrumbs";
@import "css/flat-card";