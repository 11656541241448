nav.breadcrumbs {
  ol {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;

    > li {
      display: inline-block;
      color: inherit;

      > a {
        color: inherit;
        text-decoration: underline;

        &.breadcrumbs::after {
          display: inline-block;
          content: '>';
          font-size: 80%;
          font-weight: bold;
          padding: 0 4px;
        }
      }

    }
  }
}