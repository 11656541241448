body#app-storefront-region {

  div.TopRightNav {
    color: white;
  }

  > header {
    background-size: cover;
    background-position: center;
    height: 670px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;

    > header {
      padding: 1rem;
      > h1 {
        color: white;
        font-size: 36px;
        font-weight: bold;
      }

      div.region-header-media {
        display: flex;
        gap:1rem;
        color: white;
        font-weight: bold;
        align-items: center;
      }

      div.region-header-location {
        display: flex;
        justify-content: space-between;
        > div.Orientir {
          color: white;
        }
        > section {
          display: flex;
          flex-direction: column;
          > a {
            color: white;
          }
        }
      }
    }

    > section {
      display: flex;
      justify-content: space-between;
      padding: 1rem;

    }
  }

  > main {
    padding: 1rem;
  }
}