footer.mobile {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white;
  height: 72px;
  display: flex;
  padding: 1rem;
  gap: 1rem;
  box-sizing: border-box;
  justify-content: space-between;

  > a {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    color:#8F8F8F;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    width: 72px;
    font-weight: normal;

    > icon-floor-plan,icon-heart,icon-house,icon-houses,icon-map {
      width: 24px;
      height: 24px;
    }

    &.active {
      color:black;
    }

    > label {
      text-wrap: nowrap;
    }
  }

  > span.footer-splitter {
    border-right: solid 1px silver;
    height: 100%;
    //margin: 0 8px;
  }
}