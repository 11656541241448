div[component='FlatFilterSelector'] {
  border: solid 1px silver;
  border-radius: 6px;
  display: flex;
  padding: 8px;
  box-sizing: border-box;
  gap: 8px;

  icon-houses {
    display: block;
    width: 22px;
    height: 22px;
    color: #A5A5A5;
  }

  label {
    font-weight: bold;
  }

  span {
    font-size: 12px;
    color: #676767;
  }
}